import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import EntryScreen from "./components/EntryScreen";
import PlayerScreen from "./components/PlayerScreen";
import HostScreen from "./components/HostScreen";

import useStore from "./store";

const appTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

const App: React.FC = () => {
  const isHost = useStore((state) => state.isHost);
  const isPlayer = useStore((state) => state.isPlayer);
  const error = useStore((state) => state.error);

  // useStore(console.log);

  let componentToRender = <EntryScreen />;
  if(isHost) {
    componentToRender = <HostScreen />;
  } else if(isPlayer) {
    componentToRender = <PlayerScreen />;
  }

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />

      <Snackbar open={!!error} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>

      {componentToRender}
    </ThemeProvider>
  );
};

export default App;
