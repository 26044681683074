import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import Popover from "@mui/material/Popover";
import QrCodeIcon from "@mui/icons-material/QrCode";
import SettingsIcon from "@mui/icons-material/Settings";
import { Scanner, IDetectedBarcode } from "@yudiel/react-qr-scanner";
import * as ls from "local-storage";

import useStore, { startHost, joinedHost, setConnectingToHost } from "../store";
import { connectToHost } from "../networking";

import { Container } from "@mui/material";

const EntryScreen: React.FC = () => {
  const connectingToHost = useStore((state) => state.network.connectingToHost);
  const connected = useStore((state) => state.network.connected);
  const me = useStore((state) => state.network.me);

  const [myName, setMyName] = useState(ls.get<string>("myName") || "");
  const [hostId, setHostId] = useState(ls.get<string>("hostId") || "");

  const [settingsAreOpen, setSettingsAreOpen] = useState(false);

  const [qrModalIsOpen, setQRModalIsOpen] = React.useState(false);
  const handleQRModalOpen = () => setQRModalIsOpen(true);
  const handleQRModalClose = () => setQRModalIsOpen(false);
  const handleQRScanned = (result: IDetectedBarcode[]) => {
    let hostId = result[0].rawValue;
    setHostId(hostId);
    ls.set<string>("hostId", hostId);
    handleQRModalClose();
  };

  console.log(useStore.getState());
  console.log("rendering EntryScreen");

  let disableButtons = !connected || connectingToHost;

  const handleCreateServer = startHost;

  const handleJoin = () => {
    setConnectingToHost(true);
    connectToHost(hostId, (conn) => () => {
      setConnectingToHost(false);
      conn.send({ type: "player-name", name: myName });
      joinedHost(conn, myName);
    });
  };

  const handleSettingsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log("settings open");
    setSettingsAreOpen(true);
  };

  const handleResetClick = () => {
    ls.clear();
    window.location.reload();
  }

  const popover_anchor = document.getElementById('settings-button');

  return (
    <>
      <Stack
        sx={{
          padding: "15px 10px",
          width: "100vw",
          borderBottom: "1px solid black",
        }}
        justifyContent="flex-end"
        direction="row"
        spacing={2}
        className="small"
      >
        <Stack
          sx={{ flexGrow: 1, alignItems: "flex-start", paddingLeft: "10px" }}
        >
          <div>
            <b>My ID:</b> {me?.id?.slice(0, 8)}
          </div>
        </Stack>
        <Button id="settings-button" aria-describedby="settings-popover" onClick={handleSettingsOpen}>
          <SettingsIcon />
        </Button>
        <Popover
          id="settings-popover"
          open={settingsAreOpen}
          anchorEl={popover_anchor}
          onClose={() => setSettingsAreOpen(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Button onClick={handleResetClick}>
            Reset App & Reload
          </Button>
        </Popover>
      </Stack>
      <Stack
        sx={{ height: "100vh" }}
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {!connected && (
          <Alert severity="error">Network connection failed.</Alert>
        )}
        <TextField
          id="name"
          label="Your name"
          variant="filled"
          value={myName}
          onChange={(e) => {
            setMyName(e.target.value);
            ls.set<string>("myName", e.target.value);
          }}
        />
        <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
          <InputLabel htmlFor="server-id">Server ID</InputLabel>
          <FilledInput
            id="server-id"
            // label="Server ID"
            // variant="filled"
            value={hostId}
            onChange={(e) => {
              setHostId(e.target.value);
              ls.set<string>("hostId", e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleQRModalOpen}
                  edge="end"
                >
                  <QrCodeIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button
          variant="contained"
          disabled={disableButtons}
          onClick={handleJoin}
        >
          Join
        </Button>
        <Box width={"90vw"}>
          <Divider sx={{ height: "1px" }} variant="middle" flexItem />
        </Box>
        {/* <TextField id="name-admin" label="Your name" variant="filled" />
        <TextField id="server-name" label="Server name" variant="filled" /> */}
        <Button
          variant="contained"
          onClick={handleCreateServer}
          disabled={disableButtons}
        >
          Create Server
        </Button>

        <Modal
          open={qrModalIsOpen}
          onClose={handleQRModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack
            sx={{ height: "100vh", bgcolor: "white" }}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Button variant="contained" onClick={handleQRModalClose}>
              Close QR Code
            </Button>
            <Container>
              <Scanner
                onScan={handleQRScanned}
                components={{ finder: false }}
                formats={["qr_code"]}
                allowMultiple={false}
              />
            </Container>
          </Stack>
        </Modal>

        {/* {device != null && (
          <Camera
            // style={StyleSheet.absoluteFill}
            device={device}
            isActive={true}
            codeScanner={codeScanner}
            torch={'off'}
            enableZoomGesture={true}
          />
        )} */}
      </Stack>
    </>
  );
};

export default EntryScreen;
