import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import { msToString } from "../utils";
import useStore from "../store";

interface Response {
  username: string;
  responseTime: number;
  response?: string;
}

type EnrichedResponse = Response & { diff?: number };

const Responses: React.FC<{ responses: Response[]; redacted?: boolean }> = ({
  responses,
  redacted = true,
}) => {
  if (responses.length === 0) {
    return <Box>No responses yet</Box>;
  }

  responses.sort((a, b) => a.responseTime - b.responseTime);

  let enrichedResponses = responses.map((response) => ({
    ...response,
    diff: undefined,
  })) as EnrichedResponse[];

  enrichedResponses.forEach((response, i) => {
    if (i > 0) {
      enrichedResponses[i].diff =
        response.responseTime - responses[i - 1].responseTime;
    } else {
      enrichedResponses[i].diff = undefined;
    }
  });

  return (
    <Stack
      sx={{ padding: "15px 10px" }}
      width="100%"
      spacing={1}
      className="small"
    >
      {enrichedResponses.map((response, i) => (
        <ResponseComp key={i} response={response} redacted={redacted} />
      ))}
    </Stack>
  );
};

const ResponseComp: React.FC<{
  response: EnrichedResponse;
  redacted?: boolean;
}> = ({ response, redacted = true }) => {
  const startTime = useStore((state) => state.startTime);
  return (
    <Stack sx={{ display: "inline-flex", alignItems: "flex-start" }}>
      <Box>
        <b>{response.username}</b> after{" "}
        {msToString(response.responseTime - startTime, true)}
        {response.diff !== undefined && (
          <> (+{msToString(response.diff, true)})</>
        )}
      </Box>
      {!redacted && (
        <Box paddingLeft={2} fontStyle={"italic"}>
          {response.response}
        </Box>
      )}
    </Stack>
  );
};

export default Responses;
