import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import Peer, { DataConnection } from "peerjs";

import { createPeer, broadcast } from "./networking";

export const peerStatus = { new: "NEW", established: "ESTABLISHED" };

type PeerInfo = {
  id: string;
  status: string;
  name: string;
  connection: DataConnection;
};

type ResponseInfo = {
  playerId: string;
  username: string;
  responseTime: number;
  response?: string;
};

interface AppState {
  isHost: boolean;
  isPlayer: boolean;
  playerName?: string;
  roundIsRunning: boolean;
  openForAnswers: boolean;
  startTime: number;
  stopTime: number;
  network: {
    connectingToHost: boolean;
    connected: boolean;
    me: Peer | null;
    peers: { [id: string]: PeerInfo };
  };
  responses: { [playerId: string]: ResponseInfo };
  error: string | null;
}

const useStore = create<AppState>()(
  immer((set) => ({
    isHost: false,
    isPlayer: false,
    playerName: undefined,
    roundIsRunning: false,
    openForAnswers: false,
    startTime: Date.now(),
    stopTime: Date.now(),
    network: {
      connectingToHost: false,
      connected: false,
      me: null,
      peers: {},
    },
    responses: {},
    error: null,
  }))
);

export const resetNetworking = (id: string | null = null) =>
  useStore.setState((state) => {
    let newMe = createPeer(id);
    state.network.me?.destroy();
    state.network = {
      me: newMe,
      connectingToHost: false,
      connected: false,
      peers: {},
    };
    (window as any).me = state.network.me; // for debugging
  });

export const setConnectingToHost = (connecting: boolean) =>
  useStore.setState((state) => {
    state.network.connectingToHost = connecting;
  });

export const connectedToServer = (id: string) =>
  useStore.setState((state) => {
    state.network.connected = true;
  });

export const startHost = () => {
  useStore.setState({ isHost: true });
};

export const joinedHost = (conn: DataConnection, playerName: string) => {
  useStore.setState((state) => {
    state.isPlayer = true;
    state.playerName = playerName;
    state.network.peers[conn.peer] = {
      id: conn.peer,
      name: "HOST",
      status: peerStatus.established,
      connection: conn,
    };
  });
};

export const playerConnectedToHost = (conn: DataConnection) =>
  useStore.setState((state) => {
    state.network.peers[conn.peer] = {
      id: conn.peer,
      name: "",
      status: peerStatus.new,
      connection: conn,
    };
  });

export const playerAnnouncedName = (id: string, name: string) =>
  useStore.setState((state) => {
    state.network.peers[id].name = name;
    state.network.peers[id].status = peerStatus.established;
  });

export const playerLeft = (id: string) => 
  useStore.setState((state) => {
    delete state.network.peers[id];
    if (state.isPlayer) {
      state.isPlayer = false;
      state.openForAnswers = false;
      state.roundIsRunning = false;
      state.responses = {};
    }
  });

export const startRound = (startTime: number) =>
  useStore.setState({
    roundIsRunning: true,
    openForAnswers: true,
    startTime,
  });

export const playerAnswered = (playerId: string, answer: string) =>
  useStore.setState((state) => {
    let responseTime = Date.now();
    state.responses[playerId] = {
      playerId,
      username: state.network.peers[playerId].name,
      responseTime,
      response: answer,
    };
    broadcast({
      type: "responses",
      responses: Object.values(state.responses).map((r: ResponseInfo) => ({
        playerId: r.playerId,
        username: r.username,
        responseTime: r.responseTime,
      })),
    });
  });

export const updateResponses = (responses: ResponseInfo[]) =>
  useStore.setState((state) => {
    state.responses = responses.reduce(
      (a, v) => ({ ...a, [v.playerId]: v }),
      {}
    );
  });

export const stopRound = (stopTime: number) =>
  useStore.setState({ openForAnswers: false, stopTime: stopTime });

export const endRound = () =>
  useStore.setState({
    openForAnswers: false,
    roundIsRunning: false,
    responses: {},
  });

export const setError = (message: string) => {
  useStore.setState({ error: message });
  setTimeout(() => useStore.setState({ error: null }), 5000);
};

export default useStore;
