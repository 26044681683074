import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import QrCodeIcon from '@mui/icons-material/QrCode';
import QRCode from "react-qr-code";

import Responses from "./Responses";
import Timer from "./Timer";
import { msToString } from "../utils";
import useStore, { startRound, stopRound, endRound } from "../store";
import { broadcast } from "../networking";

const HostScreen: React.FC = () => {
  const me = useStore((state) => state.network.me);
  const playerCount = useStore(
    (state) => Object.keys(state.network.peers).length
  );
  const startTime = useStore((state) => state.startTime);
  const stopTime = useStore((state) => state.stopTime);
  const roundIsRunning = useStore((state) => state.roundIsRunning);
  const openForAnswers = useStore((state) => state.openForAnswers);
  const responses = useStore((state) => state.responses);

  const [qrModalIsOpen, setQRModalIsOpen] = React.useState(false);
  const handleQRModalOpen = () => setQRModalIsOpen(true);
  const handleQRModalClose = () => setQRModalIsOpen(false);

  if (!me) console.error("me is null");
  let serverId = me?.id.slice(0, 8) as string;

  const handleStartRound = () => {
    let startTime = Date.now();
    startRound(startTime);
    broadcast({ type: "start-round", startTime });
  };

  const handleStopRound = () => {
    let stopTime = Date.now();
    stopRound(stopTime);
    broadcast({ type: "stop-round", stopTime });
  };

  const handleEndRound = () => {
    endRound();
    broadcast({ type: "end-round" });
  };

  return (
    <>
      <Stack
        sx={{
          padding: "15px 10px",
          width: "100vw",
          borderBottom: "1px solid black",
        }}
        justifyContent="flex-end"
        direction="row"
        spacing={2}
        className="small"
      >
        <Stack
          sx={{ flexGrow: 1, alignItems: "flex-start", paddingLeft: "10px" }}
        >
          <div>
            <b>Server ID:</b> {serverId}
          </div>
          <div>
            <b># of players:</b> {playerCount}
          </div>
        </Stack>
        <Button onClick={handleQRModalOpen}>
          <QrCodeIcon />
        </Button>
        <Modal
          open={qrModalIsOpen}
          onClose={handleQRModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack
            sx={{ height: "100vh" }}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Button variant="contained" onClick={handleQRModalClose}>Close QR Code</Button>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "80%", width: "80%" }}
              value={serverId}
              viewBox={`0 0 256 256`}
            />
          </Stack>
        </Modal>
      </Stack>
      <Stack
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}
        margin="30px 10px"
      >
        {!roundIsRunning ? (
          <Stack alignItems="center">
            <Button variant="contained" onClick={handleStartRound}>
              Start Answering Session
            </Button>
            <br />
            <span className="small">
              This starts the timer and allows players to answer
            </span>
          </Stack>
        ) : (
          <>
            <Stack
              direction="row"
              width="100%"
              spacing={2}
              justifyContent="center"
            >
              <Box>Open {openForAnswers ? "since" : "for"}:</Box>
              <Box>
                {openForAnswers ? (
                  <Timer />
                ) : (
                  msToString(stopTime - startTime, true)
                )}
              </Box>
            </Stack>
            <Button
              variant="contained"
              onClick={openForAnswers ? handleStopRound : handleEndRound}
            >
              {openForAnswers ? "Stop" : "End"} Answering Session
            </Button>
            <Box width={"90vw"}>
              <Divider sx={{ height: "1px" }} variant="middle" flexItem />
            </Box>
            <Responses redacted={false} responses={Object.values(responses)} />
          </>
        )}
      </Stack>
    </>
  );
};

export default HostScreen;
