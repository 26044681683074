import Peer, { DataConnection } from "peerjs";
import { v4 as uuidv4 } from "uuid";
import * as ls from "local-storage";

import useStore, {
  peerStatus,
  connectedToServer,
  playerConnectedToHost,
  playerAnnouncedName,
  playerLeft,
  startRound,
  stopRound,
  endRound,
  playerAnswered,
  updateResponses,
  setConnectingToHost,
  setError,
} from "./store";

type ConnectionOpenCallback = (conn: DataConnection) => () => void;

const uuidSuffix = "-e190-417d-9389-6dd37f1853ed";

export const createPeer = (id: string | null = null) => {
  let myId = id || uuidv4().slice(0, 8) + uuidSuffix;
  ls.set<string>("myId", myId);
  let p = new Peer(myId, {
    // host: "quizz.kaktus42.de",
    // port: 443,
    // path: "/srv, ",
    config: {
      iceServers: [
        { urls: "stun:stun.relay.metered.ca:80" },
        {
          urls: "turn:global.relay.metered.ca:80",
          username: "b9c2fe3dea537e3492984151",
          credential: "+pg3AWnEXoQpHvRe",
        },
        {
          urls: "turn:global.relay.metered.ca:80?transport=tcp",
          username: "b9c2fe3dea537e3492984151",
          credential: "+pg3AWnEXoQpHvRe",
        },
        {
          urls: "turn:global.relay.metered.ca:443",
          username: "b9c2fe3dea537e3492984151",
          credential: "+pg3AWnEXoQpHvRe",
        },
        {
          urls: "turns:global.relay.metered.ca:443?transport=tcp",
          username: "b9c2fe3dea537e3492984151",
          credential: "+pg3AWnEXoQpHvRe",
        },
      ],
    },
  });
  p.on("error", onPeerError);
  p.on("open", onPeerOpen);
  p.on("connection", onPeerConnection);
  p.on("close", onPeerClose);
  p.on("disconnected", onPeerDisconnected);
  return p;
};

export const connectToHost = (hostId: string, cb: ConnectionOpenCallback) => {
  let me = useStore.getState().network.me;
  if (!me) return;
  let conn = me.connect(hostId + uuidSuffix);
  conn.on("open", cb(conn));
  conn.on("error", onConnectionError(conn.peer));
  conn.on("data", onConnectionData(conn.peer));
  conn.on("close", onConnectionClose(conn.peer));
};

export const broadcast = (data: any) => {
  let peers = useStore.getState().network.peers;
  for (let id in peers) {
    if (peers[id].status !== peerStatus.established) continue;
    peers[id].connection.send(data);
  }
};

export const submitAnswer = (answer: string) => {
  broadcast({ type: "answer", answer });
};

const onPeerError = (err: any) => {
  console.error(err);

  if (useStore.getState().network.connectingToHost) {
    setConnectingToHost(false);
    setError("Failed to connect to host.");
  }
};

const onPeerOpen = (id: string) => {
  console.error("onPeerConnection");
  connectedToServer(id);
};

const onPeerConnection = (conn: DataConnection) => {
  console.error("onPeerConnection", conn);
  conn.on("open", onConnectionOpen(conn.peer));
  conn.on("error", onConnectionError(conn.peer));
  conn.on("data", onConnectionData(conn.peer));
  conn.on("close", onConnectionClose(conn.peer));

  playerConnectedToHost(conn);
};

const onPeerClose = () => {
  console.error("onPeerClose");
};

const onPeerDisconnected = () => {
  console.error("onPeerDisconnected");
  let me = useStore.getState().network.me;
  me?.reconnect();
};

const onConnectionOpen = (playerID: string) => () => {
  console.error("onConnectionOpen");
};

const onConnectionError = (playerID: string) => (err: any) => {
  console.error("onConnectionError", playerID, err);
  playerLeft(playerID);

  if (useStore.getState().network.connectingToHost) {
    setConnectingToHost(false);
    setError("Failed to connect to host.");
  }
};

const onConnectionData = (playerID: string) => (data: any) => {
  console.error("onConnectionData");
  if (data.type === "player-name") {
    playerAnnouncedName(playerID, data.name);
  } else if (data.type === "answer") {
    playerAnswered(playerID, data.answer);
  } else if (data.type === "start-round") {
    startRound(data.startTime);
  } else if (data.type === "stop-round") {
    stopRound(data.stopTime);
  } else if (data.type === "end-round") {
    endRound();
  } else if (data.type === "responses") {
    updateResponses(data.responses);
  } else {
    console.error("Unknown data type", data);
  }
};

const onConnectionClose = (playerID: string) => () => {
  console.error("onConnectionClose");
  playerLeft(playerID);
};
