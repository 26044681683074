import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import Responses from "./Responses";
import Timer from "./Timer";
import { msToString } from "../utils";
import { submitAnswer } from "../networking";
import useStore from "../store";

const PlayerScreen: React.FC = () => {
  const name = useStore((state) => state.playerName);
  const hostId = useStore((state) =>
    Object.values(state.network.peers)[0].connection.peer.slice(0, 8)
  );
  const startTime = useStore((state) => state.startTime);
  const stopTime = useStore((state) => state.stopTime);
  const roundIsRunning = useStore((state) => state.roundIsRunning);
  const openForAnswers = useStore((state) => state.openForAnswers);
  const responses = useStore((state) => state.responses);

  const [myAnswer, setMyAnswer] = useState("");

  const handleSubmitAnswer = () => {
    submitAnswer(myAnswer);
  };

  return (
    <>
      <Stack
        sx={{
          padding: "15px 10px",
          width: "100vw",
          borderBottom: "1px solid black",
        }}
        justifyContent="flex-end"
        direction="row"
        spacing={2}
        className="small"
      >
        <Container
          sx={{ display: "inline-flex", flexGrow: 1, alignItems: "center" }}
        >
          <div>
            <b>Player Name:</b> {name}
            <br />
            <b>Host ID:</b> {hostId}
          </div>
        </Container>
        <Container
          sx={{ display: "inline-flex", alignItems: "center", width: "auto" }}
        >
          <Icon>settings</Icon>
        </Container>
      </Stack>
      {!roundIsRunning ? (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          margin="30px 10px"
        >
          <Container sx={{ textAlign: "center" }} className="title-small">
            Waiting for the host to start an answering session
          </Container>
        </Stack>
      ) : (
        <Stack
          justifyContent="flex-start"
          alignItems="center"
          spacing={4}
          margin="30px 10px"
        >
          <Stack
            direction="row"
            width="100%"
            spacing={2}
            justifyContent="center"
          >
            <Box>Open {openForAnswers ? "since" : "for"}:</Box>
            <Box>
              {openForAnswers ? (
                <Timer />
              ) : (
                msToString(stopTime - startTime, true)
              )}
            </Box>
          </Stack>
          <Container sx={{ textAlign: "center" }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Your answer"
              multiline
              fullWidth
              rows={4}
              value={myAnswer}
              onChange={(e) => setMyAnswer(e.target.value)}
            />
          </Container>
          <Button
            variant="contained"
            disabled={!openForAnswers}
            onClick={handleSubmitAnswer}
          >
            Submit Answer
          </Button>
          <Responses responses={Object.values(responses)} />
        </Stack>
      )}
    </>
  );
};

export default PlayerScreen;
