import React from 'react';
import ReactDOM from 'react-dom/client';
import * as ls from "local-storage";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import useStore, { resetNetworking } from './store';

const initApp = () => {
  resetNetworking(ls.get("myId"));
  (window as any).getState = useStore.getState; // for debugging
};

initApp();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
