import React, { useEffect, useState } from "react";

import useStore from "../store";
import { msToString } from "../utils";

const Timer: React.FC = () => {
  const [value, setValue] = useState(false); // force update state
  const startTime = useStore((state) => state.startTime);

  useEffect(() => {
    const timer = setTimeout(() => setValue(!value), 50);
    return () => clearTimeout(timer);
  });

  return <>{msToString(Date.now() - startTime)}</>;
};

export default Timer;
