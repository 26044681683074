export const msToString = (ms: number, cut: boolean = false) => {
  let res = new Date(ms).toISOString().slice(14, 23);
  if (cut && res.startsWith("00:")) {
    res = res.slice(3);
  }
  return res;
};

export const randomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
}